<template>
	<div class="tcontainer">
		<Toast />
		<div class="clearfix ttitle">
			<div class="title">Track Report</div>
		</div>
		<div style="padding-top: 20px;">
			<div class="container">
				<div class="row">
					<div class="col-sm-4"></div>
					<div class="col-sm-4">
						<div class="form-group">
							<select class="form-control" v-model="fdata.report_type" @blur="fcontrol.report_type.touched = true">
								<option value="xray">X-Ray</option>
								<option value="ecg">ECG</option>
							</select>
							<div class="invalid-feedback" v-if="fcontrol.report_type?.hasError">
								<span v-if="fcontrol.report_type.errors.required">You must select report type</span>
							</div>
						</div>
						<div class="form-group ui-float-label">
							<input class="form-control"
								:class="{ 'is-invalid': fcontrol.report_id?.hasError && fcontrol.report_id.touched }"
								placeholder=" " type="number" v-model="fdata.report_id" @blur="fcontrol.report_id.touched = true">
							<label>Report Id</label>
							<div class="invalid-feedback" v-if="fcontrol.report_id?.hasError">
								<span v-if="fcontrol.report_id.errors.required">You must enter report id</span>
							</div>
						</div>
						<div class="form-group ui-float-label">
							<input class="form-control"
								:class="{ 'is-invalid': fcontrol.phone_number?.hasError && fcontrol.phone_number.touched }"
								placeholder=" " type="number" v-model="fdata.phone_number"
								@blur="fcontrol.phone_number.touched = true">
							<label>Phone Number</label>
							<div class="invalid-feedback" v-if="fcontrol.phone_number?.hasError">
								<span v-if="fcontrol.phone_number.errors.required">You must enter phone number</span>
							</div>
						</div>
						<div class="clearfix">
							<div class="pull-right">
								<button @click="submitForm" class="btn btn-primary btn-lg">Print Report</button>
							</div>
						</div>
					</div>
					<div class="col-sm-4"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Toast from 'primevue/toast';
export default {
	components: {
		Toast,
	},
	data() {
		return {
			saving: false,
			fdata: {
				report_type: 'xray',
				report_id: '',
				phone_number: '',
			},
			fvalidator: {
				report_type: 'required',
				report_id: 'required',
				phone_number: 'required',
			}
		}
	},
	methods: {
		async submitForm() {
			if (this.saving) return;
			if (this.fHasError()) return false;
			this.saving = true;

			let res = await this.post("check-report-data", this.fdata);

			if (res.success){
				let data =  btoa(res.center_id+','+res.id).replaceAll(/=/ig, "");
				this.$router.push({ 
						path: '/print-' + this.fdata.report_type + '-report', 
						query: {
							data
						} 
				});
			} else {
				this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Report not found', life: 3000 });
			}

			this.saving = false;
		}
	},
}
</script>